import React from 'react';
import { StarIcon } from "@heroicons/react/24/outline";


const SuccessStories = () => {
  const stories = [
    {
      company: 'Concol Chile S.A',
      project: 'Proyecto Cardones Polpaico',
      description: 'Administración integligente de bodega de más de 3MM usd , envío de 17.000 Tons entre Copiapo y santiago, con 3 bodegas centrales, armado de torres de alta tensión entre 550 a 220, 4 modelos de torres contabilización, trazabilidad de cada una de las piezas entre las bodegas y distribución inteligente.',
      stars: 4.9,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@Concol',
    },
    {
      company: 'EDEMSA',
      project: 'Desarrollo de Orden de compra y licitaciones inteligentes',
      description: 'Desarrollo a medida de un sistema capaz de realizar ordenes de compra y proceso de licitación en base a una matriz de desiciones, el cual por medios de algoritmos de Machine Learning recomienda cual es el mejor proveedor de acuerdo a la matriz seleccionada con indicadores dados en lapsu de tiempo, ademas de ello es capaz de obtener y cuantificar el ahorro en los costos según el proceso.',
      stars: 4.7,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@EDEMSA',
    },
    {
      company: 'ISA INTERCHILE',
      project: 'Desarrollo de Panel de control',
      description: 'Desarrollo e  Integración de un panel de control para el seguimiento de proyectos, con metricas establecidas por el cliente, indicadores de gestión y capacidad de toma de desiciones, se recopilan los datos en la punta para despues de ser procesados, ordenados llevar estos datos al panel de control.',
      stars: 4.5,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@ISA INTERCHILE',
    },
    {
      company: 'WOL',
      project: 'Sistema de Contratación de Maestros Obras menores.',
      description: 'Desarro en el sistema de contratación de maestros en X lugares a la redonda con atención hacia el cliente cercano, capacidad de ofertar por Obra. Sistema Movil Android e IOS',
      stars: 4.5,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@StarUp',
    },
    {
      company: 'CGO',
      project: 'Sistema de Monitoreo de RUTAS Optimas',
      description: 'sistema de trazabilidad vehicular para vehiculos y mercadería transportada, disminuyendo los tiempos de traslados y entrega de mercaderías, ideal para empresas de transporte o transportitas independientes.',
      stars: 4.5,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@CGO',
    },
    {
      company: 'NIXTRUCK',
      project: 'Sistema de Trazabilidad de materiales operacionales.',
      description: 'Sistema de Gestión en la trazabilidad de materiales operativos, cuenta con identificación de materiales con RFID, Detección por recnocimiento de id, codigos de barra codigo QR. Ideal para empresas de construcción Minería y Empresas Eléctricas',
      stars: 4.5,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@Nixtruck',
    },
    {
      company: 'Prometheus',
      project: 'Web Chat para identificación de clientes. No Asistance',
      description: 'Sistema de webchat para atención de clientes, vendedores donde por medio de una interacción al whatapps , telegram o webchat, somos capaces de seleccionar al cliente que realmente está interesado, pudieron elevar las ventas y la atención de las PYMES en Chile en un 33% a 42%. Analiza sentieminetos de tus clientes y como tienen la percepción de tu marca producto o servicio.',
      stars: 4.9,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@Prometehus',
    },
    {
      company: 'Liwen',
      project: 'Sistema de Asistencia',
      description: 'Sistema de control de asistencia , con reconocimiento facial , geolozalización ideal para empresas de mercaderistas y vendedores de terreno, solicitudes de vacaciones , permisos o cursos en linea. ',
      stars: 4.5,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@MLiwen',
    },
    {
      company: 'Implementaciones de ERP',
      project: 'Sistema de implementación de ERP',
      description: 'Sistema de control de su empresa, realizamos la migración o procesos que actualmente posees y los deribamos hacia este software el cuial simplemente puede llevar el control de tus gastos, facturación, clientes, hasta un e-commerce.',
      stars: 4.2,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@MLiwen',
    },
    {
      company: 'ICOV-TECHNOLOGIES',
      project: 'Transformamos Empresas',
      description: 'Transformamos productos y procesos en base al sacrificio de los emprendedores en Exitos y Felicidad, eso es lo que nos lleva a seguir trabajando por cada emprendedor. Consulta por una reunión y conversmos que te complica.',
      stars: 5.0,
      avatar: '/images/icov-iso.png', // Reemplaza por la imagen correspondiente
      handle: '@ICOV-TECH',
    },
  ];

  return (
    <section>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8 mb-10">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">Casos de Éxito</h2>
        <div className="sliderContainer">
          <div className="slider">
            <div className="tarjetas">
              {stories.map((story, index) =>
                <div className="tarjetaSlider" key={index}>
                  <div className="flex items-center mb-2 ">
                    <img src={story.avatar} alt={story.company} className="h-12 w-12 rounded-full mr-4 " />
                    <div>
                      <h3 className="text-lg font-bold">{story.company}</h3>
                      <p className="text-gray-500">{story.handle}</p>
                    </div>
                  </div>
                  <h4 className="text-xl font-semibold mb-3">{story.project}</h4>
                  <p className="text-gray-600 mb-4">{story.description}</p>
                  <div className="tarjetaEstrellas">
                    <StarIcon className="h-5 w-5 text-yellow-400 mr-1" />
                    <span>{story.stars}/5</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStories;
